/* src/App.css */
.App {
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7; /* 부드러운 배경 색상 */
  margin: 0;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
}

h1, h2 {
  color: #333; /* 기본 텍스트 색상 */
}

button {
  margin: 15px;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff; /* 기본 버튼 색상 - 파란색 */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3; /* 버튼 호버 시 어두운 파란색 */
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  width: calc(100% - 20px);
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.start-page,
.quiz-page,
.result-page,
.end-page {
  margin-top: 20px;
}

.end-page {
  color: #007bff; /* 종료 페이지 텍스트 색상 */
}
